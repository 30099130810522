<template>
    <van-config-provider :theme-vars="myTheme">
        <div class="flex flex-col items-center pt-32">
            <van-form ref="form" class="py-4 w-full md:w-2/3 lg:w-1/3" validate-first validate-trigger="onSubmit">
                <van-cell-group class="shadow-md" inset>
                    <van-cell-group :title="lang[userLang].accountTitle">
                        <van-field v-if="!editMode" v-model="formData.school.name" readonly clickable is-link
                            :label="lang[userLang].schoolLabel" :placeholder="lang[userLang].placeholderPick"
                            @click="showSchools = true"
                            :rules="[{ required: true, message: lang[userLang].emailFormatMessage }]" />
                        <div class="text-center">
                            <van-image class="mt-2" v-if="formData.school.name" round fit="contain" width="8rem"
                                height="8rem" :src="Env.schoolPath + formData.school.icon" />
                        </div>

                        <van-cell v-if="editMode" :title="lang[userLang].userTypeLabel"
                            :value="formData.userType.text" />
                        <van-field v-else v-model="formData.userType.text" readonly clickable is-link
                            :label="lang[userLang].userTypeLabel" :placeholder="lang[userLang].placeholderPick"
                            @click="showUserType = true"
                            :rules="[{ required: true, message: lang[userLang].userTypeMessage }]" />

                        <van-field :label="lang[userLang].isStudent" v-if="formData.parent">
                            <template #input>
                                <van-radio-group v-model="isParent" direction="horizontal">
                                    <van-radio :name="false">{{ lang[userLang].student }}</van-radio>
                                    <van-radio :name="true" @click="pickParent()">{{ lang[userLang].parent
                                        }}</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>

                        <van-field v-if="!editMode" v-model="formData.system.email" maxlength="100"
                            :label="lang[userLang].emailLabel" :placeholder="lang[userLang].placeholderEnter"
                            :rules="[{ required: true, message: lang[userLang].emailMessage, validator: emailValidator }]" />

                        <van-field v-model="formData.system.password" type="password" maxlength="50"
                            :label="lang[userLang].passLabel" :placeholder="lang[userLang].placeholderEnter"
                            :rules="[{ required: true, message: lang[userLang].passFormatMessage, validator: passValidator }]"
                            :disabled="!editPassword" />

                        <van-field v-if="editPassword" v-model="formData.passwordCheck" type="password" maxlength="50"
                            :label="lang[userLang].passConfirm" :placeholder="lang[userLang].passConfirmMessage"
                            :rules="[{ required: true, message: lang[userLang].passConfirmMessage, validator: passCheckValidator, firstPass: formData.system.password }]" />
                    </van-cell-group>
                    <div v-if="editMode" class="text-center py-2">
                        <div v-if="editPassword">
                            <van-button plain type="danger" size="small" @click="updatePassword()" class="mr-8">{{
        lang[userLang].buttonFinishPass }}
                            </van-button>
                            <van-button plain type="primary" size="small" @click="editPassword = false;">{{
        lang[userLang].buttonCancel
    }}</van-button>
                        </div>
                        <van-button v-else plain type="primary" size="small" @click="editPassword = true;">{{
        lang[userLang].buttonEditPass
    }}
                        </van-button>
                    </div>
                    <br />
                    <van-cell-group :title="lang[userLang].parentTitle" v-if="isParent">
                        <van-field v-model="formData.parent.firstName" maxlength="50"
                            :label="lang[userLang].parentFirst" :placeholder="lang[userLang].placeholderEnter"
                            :rules="[{ required: true, message: lang[userLang].parentFirstMessage }]"
                            :disabled="!editParent" />

                        <van-field v-model="formData.parent.lastName" maxlength="50" :label="lang[userLang].parentLast"
                            :placeholder="lang[userLang].placeholderEnter"
                            :rules="[{ required: true, message: lang[userLang].parentLastMessage }]"
                            :disabled="!editParent" />

                        <van-field v-model.number="formData.parent.call" type="digit" maxlength="15"
                            :label="lang[userLang].parentNumber"
                            :rules="[{ required: true, message: lang[userLang].parentNumberMessage, validator: callValidator }]"
                            :disabled="!editParent" />
                    </van-cell-group>
                    <div v-if="editMode && isParent" class="text-center py-2">
                        <div v-if="editParent">
                            <van-button plain type="danger" size="small" @click="updateParent()" class="mr-8">{{
        lang[userLang].buttonFinishParent }}</van-button>
                            <van-button plain type="primary" size="small" @click="editParent = false;">{{
        lang[userLang].buttonCancel
    }}</van-button>
                        </div>
                        <van-button v-else plain type="primary" size="small" @click="editParent = true;">{{
        lang[userLang].buttonEditParent
    }}</van-button>
                    </div>
                    <br />
                    <van-cell-group :title="infoTitle">
                        <van-field v-model="formData.profile.firstName" maxlength="50" :label="lang[userLang].firstName"
                            :placeholder="lang[userLang].placeholderEnter"
                            :rules="[{ required: true, message: lang[userLang].firstNameMessage }]"
                            :formatter="textFormatter" format-trigger="onBlur" :disabled="!editProfile" />

                        <van-field v-model="formData.profile.lastName" maxlength="50" :label="lang[userLang].lastName"
                            :placeholder="lang[userLang].placeholderEnter"
                            :rules="[{ required: true, message: lang[userLang].lastNameMessage }]"
                            :formatter="textFormatter" format-trigger="onBlur" :disabled="!editProfile" />

                        <van-field v-model="formData.profile.showName" maxlength="50" :label="lang[userLang].showName"
                            :placeholder="lang[userLang].placeholderEnter" :formatter="textFormatter"
                            format-trigger="onBlur" :disabled="!editProfile" />

                        <van-field :label="lang[userLang].gender"
                            :rules="[{ required: true, message: lang[userLang].genderMessage }]">
                            <template #input>
                                <van-radio-group v-model="formData.profile.gender" direction="horizontal"
                                    :disabled="!editProfile">
                                    <van-radio :name="ug.value" v-for="ug in userGenders" :key="ug.value">{{ ug.text
                                        }}</van-radio>
                                </van-radio-group>
                            </template>
                        </van-field>

                        <van-field v-model="formData.profile.dob" readonly clickable is-link :label="lang[userLang].dob"
                            :placeholder="lang[userLang].placeholderPick" @click="showDOB = true"
                            :rules="[{ required: true, message: lang[userLang].dobMessage }]"
                            :disabled="!editProfile" />

                        <van-field :label="lang[userLang].icon">
                            <template #input>
                                <van-uploader class="ml-4" v-model="fileList" :after-read="loadIcon" :max-count="1"
                                    preview-size="96px" :max-size="1500 * 1024" @oversize="iconOversize"
                                    :before-read="checkIcon" :before-delete="deleteIcon" />
                            </template>
                            <template #button>
                                <van-button size="mini" plain type="primary" @click="changeIcon()" icon="replay"
                                    :disabled="!editProfile">{{ lang[userLang].buttonRandom }}</van-button>
                            </template>
                        </van-field>

                        <van-field v-model="formData.profile.bio" rows="6" autosize :label="lang[userLang].bio"
                            type="textarea" maxlength="300" :placeholder="lang[userLang].bioPlaceholder" show-word-limit
                            clearable :formatter="textFormatter" :disabled="!editProfile" />

                        <van-field v-model="tagIn" maxlength="50" :label="lang[userLang].personalTag"
                            :placeholder="lang[userLang].personalTagPlaceHolder" @keyup.enter="addTag()">
                            <template #extra>
                                <div class="w-32">
                                    <van-tag class="mr-1 cursor-pointer" type="primary"
                                        v-for="(tag, index) in formData.meta.tags.personal" :key="index"
                                        @click="removeTag(index)">{{ tag }}</van-tag>
                                </div>
                            </template>
                        </van-field>
                    </van-cell-group>
                    <br />
                    <van-cell-group :title="lang[userLang].contactMethods">
                        <van-field v-model="formData.profile.contact[index].value"
                            v-for="(info, index) in formData.profile.contact" :key="index" label="&nbsp;"
                            :left-icon="Env.displayPath + info.icon" :placeholder="lang[userLang].placeholderEnter"
                            maxlength="50">
                            <template #right-icon>
                                <van-icon name="clear" @click="removeContact(index)" size="16px" />
                            </template>
                        </van-field>
                    </van-cell-group>
                    <div class="flex flex-row-reverse">
                        <van-button class="my-2 mr-2" plain type="primary" icon="plus" @click="showContacts = true"
                            :disabled="!editProfile">{{ lang[userLang].buttonAdd }}</van-button>
                    </div>
                    <br />
                    <div v-if="editMode" class="text-center py-2">
                        <div v-if="editProfile">
                            <van-button plain type="danger" size="small" @click="updateProfile()" class="mr-8">{{
        lang[userLang].buttonFinishPersonal }}</van-button>
                            <van-button plain type="primary" size="small" @click="editProfile = false;">{{
        lang[userLang].buttonCancel
    }}</van-button>
                        </div>
                        <van-button v-else plain type="primary" size="small" @click="editProfile = true;">{{
        lang[userLang].buttonEditPersonal }}</van-button>
                    </div>
                </van-cell-group>
            </van-form>
            <div v-if="!editMode" class="text-center my-16">
                <van-button class="w-32" type="primary" :loading="submitting" @click="doSignup()">{{
        lang[userLang].buttonSubmit
    }}</van-button>
            </div>
        </div>
    </van-config-provider>
    <van-popup v-model:show="showSchools" position="bottom" round>
        <van-picker show-toolbar :columns="schoolNames" @cancel="showSchools = false" @confirm="pickSchool"
            :confirm-button-text="lang[userLang].confirm" :cancel-button-text="lang[userLang].cancel" />
    </van-popup>
    <van-popup v-model:show="showUserType" position="bottom" round>
        <van-picker show-toolbar :columns="userTypes" @cancel="showUserType = false" @confirm="pickUserType"
            :confirm-button-text="lang[userLang].confirm" :cancel-button-text="lang[userLang].cancel" />
    </van-popup>
    <van-popup v-model:show="showDOB" position="bottom" round>
        <van-datetime-picker v-model="today" type="date" :title="lang[userLang].dateTitle"
            :confirm-button-text="lang[userLang].confirm" :cancel-button-text="lang[userLang].cancel"
            :min-date="minDate" :max-date="maxDate" @confirm="pickDOB" @cancel="showDOB = false" />
    </van-popup>
    <van-action-sheet v-model:show="showContacts" :actions="contacts" @select="pickContact" />
</template>

<script>
import lang from "@/langs/PageSignupLang.js";
import {
    reactive,
    ref,
    onMounted,
    computed
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import UserGenders from "@/static_values/StaticUserGenders.js";
import UserTypes from "@/static_values/StaticUserTypes.js";
import Contacts from "@/static_values/StaticContactMethods.js";
import GetSchools from "@/asyncs/GetSchools.js";
import emailValidator from "@/logics/ValidatorEmail.js";
import passValidator from "@/logics/ValidatorPassword.js";
import passCheckValidator from "@/logics/ValidatorPasswordCheck.js";
import callValidator from "@/logics/ValidatorCellNumber.js";
import textFormatter from "@/logics/FormatterTextInput.js";
import {
    Button,
    Form,
    Field,
    Picker,
    Popup,
    Image as VanImage,
    CellGroup,
    Cell,
    Radio,
    RadioGroup,
    Icon,
    DatetimePicker,
    ActionSheet,
    Toast,
    Uploader,
    Notify,
    Tag
} from "vant";
import myTheme from "@/logics/MyVantTheme.js";
import axios from "axios";
import Env from "@/logics/Envs.js";
import GetSession from "@/asyncs/GetSession.js";
import UpdateProfile from "@/asyncs/UpdateProfile.js";
import Logout from "@/asyncs/Logout.js";

export default {
    components: {
        [Button.name]: Button,
        [Form.name]: Form,
        [Field.name]: Field,
        [Picker.name]: Picker,
        [Popup.name]: Popup,
        [VanImage.name]: VanImage,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [Icon.name]: Icon,
        [DatetimePicker.name]: DatetimePicker,
        [ActionSheet.name]: ActionSheet,
        [Toast.name]: Toast,
        [Uploader.name]: Uploader,
        [Tag.name]: Tag
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("hideMenu");
        store.commit("setPageTitle", lang[store.state.lang].title);

        const router = useRouter();
        const route = useRoute();
        const mode = route.params.mode;

        const editMode = ref(false); //mode of new
        const editPassword = ref(true); //mode of new
        const editParent = ref(true); //mode of new
        const editProfile = ref(true); //mode of new
        const fileList = ref([]);

        const formData = reactive({
            userType: {},
            school: {},
            system: {},
            profile: {
                contact: []
            },
            meta: {
                tags: {
                    personal: ["民族民间舞", "古典舞", "芭蕾舞"],
                    system: []
                }
            }
        });
        const isParent = ref(false);
        const infoTitle = ref(lang[store.state.lang].infoTitle);

        const asyncInit = async function () {
            if (mode === "edit") {
                store.commit("setPageTitle", lang[store.state.lang].titleEdit);
                store.commit("showMenu");
            }
            store.commit("showMenu");
            const school = store.state.school;
            const user = store.state.user;
            formData.school = school;
            formData.userType = {
                text: UserTypes[user.userType].name[store.state.lang]
            }
            if (user.parent) {
                isParent.value = true;
                formData.parent = user.parent;
            } else if (UserTypes.student.value === user.userType) {
                formData.parent = {};
            }
            formData.system = {};
            formData.profile = user.profile;
            formData.meta = user.meta;
            fileList.value.push({
                url: Env.userPath + formData.profile.icon
            });
            if (formData.profile.contact.length) {
                formData.profile.contact.forEach(function (item) {
                    item.icon = Contacts[item.type].icon;
                });
            }
        };

        if (mode === "edit") {
            editMode.value = true;
            editPassword.value = false;
            editParent.value = false;
            editProfile.value = false;
            GetSession({
                store,
                router,
                route
            }, asyncInit);
        }

        const pickParent = function () {
            Toast({
                message: lang[store.state.lang].parentTip,
                duration: 5000
            });
        };

        const userTypes = Object.values(UserTypes).map(a => {
            return {
                text: a.name[store.state.lang],
                value: a.value
            };
        });
        const showUserType = ref(false);
        const pickUserType = function (item) {
            if (item.value === UserTypes.student.value) {
                formData.parent = {};
                infoTitle.value = lang[store.state.lang].studentInfoTitle;
            } else {
                isParent.value = false;
                delete formData.parent;
                infoTitle.value = lang[store.state.lang].infoTitle;
            }
            formData.userType = item;
            showUserType.value = false;
        };

        const userGenders = Object.entries(UserGenders).map(a => {
            return {
                text: a[1][store.state.lang],
                value: a[0]
            };
        });

        const showDOB = ref(false);
        const pickDOB = function (date) {
            formData.profile.dob = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
            showDOB.value = false;

            if (formData.userType.value) {
                if (formData.userType.value === UserTypes.student.value) {
                    const nowYear = new Date().getFullYear();
                    if ((nowYear - date.getFullYear()) < 18) {
                        isParent.value = true;
                        Toast({
                            message: lang[store.state.lang].parentAgeTip,
                            duration: 5000
                        });
                    }
                }
            }
        };

        const changeIcon = function () {
            iconChanged = true;
            const number = Math.floor(Math.random() * 100) + 1;
            const svgUrl = "https://api.dicebear.com/7.x/adventurer/svg?seed=" + formData.profile.firstName + "_" + formData.profile.lastName + "_" + number;

            axios.get(svgUrl, {
                responseType: "blob"
            }).then(function (response) {
                const reader = new FileReader();
                reader.onload = function () {
                    if (fileList.value.length > 0) {
                        fileList.value.shift();
                    }
                    fileList.value.push({
                        url: reader.result,
                        file: response.data,
                        isImage: true
                    });
                };
                reader.readAsDataURL(response.data);
            });
        };
        if (mode !== "edit") {
            onMounted(changeIcon);
        }
        const iconOversize = function () {
            Toast(lang[store.state.lang].fileMessage);
        };
        const checkIcon = function (file) {
            if (file.type !== "image/jpeg" &&
                file.type !== "image/png" &&
                file.type !== "image/gif" &&
                file.type !== "image/svg+xml") {
                Toast(lang[store.state.lang].imageMessage);
                return false;
            }
            return true;
        };

        const loadIcon = function () {
            if (fileList.value.length > 1) {
                fileList.value.shift();
            }
        };

        const showSchools = ref(false);
        const schools = ref([]);
        const schoolNames = ref([]);
        GetSchools(schools, schoolNames);
        const pickSchool = function (text, index) {
            formData.school = schools.value[index];
            store.commit("setSchool", schools.value[index]);
            showSchools.value = false;
        }

        const showContacts = ref(false);
        const contacts = Object.values(Contacts);
        const pickContact = function (item) {
            item.value = "";
            formData.profile.contact.push(item);
            showContacts.value = false;
        };
        const removeContact = function (index) {
            if (!editProfile.value) {
                return;
            }
            formData.profile.contact.splice(index, 1);
        };

        const tagIn = ref("");
        const addTag = function () {
            const parts = tagIn.value.split(",");
            parts.forEach(function (item) {
                const afterTrim = item.trim();
                if (afterTrim) {
                    formData.meta.tags.personal.push(item.trim());
                }
            });
            tagIn.value = "";
        };
        const removeTag = function (index) {
            if (!editProfile.value) {
                return;
            }
            formData.meta.tags.personal.splice(index, 1);
        };

        const form = ref(null);
        const submitting = ref(false);
        const doSignup = function () {
            formData.system.email = formData.system.email.toLowerCase();
            form.value.validate().then(function () {
                if (formData.userType.value === UserTypes.student.value) {
                    const nowYear = new Date().getFullYear();
                    const birthYear = parseInt(formData.profile.dob.split("-")[0]);
                    if ((nowYear - birthYear) < 18 && !isParent.value) {
                        isParent.value = true;
                        throw [{
                            message: lang[store.state.lang].parentAgeTip
                        }];
                    }
                }
                if (!fileList.value.length) {
                    throw [{
                        message: lang[store.state.lang].iconMessage
                    }];
                }
                if (isParent.value) {
                    if (formData.profile.firstName === formData.parent.firstName && formData.profile.lastName === formData.parent.lastName) {
                        throw [{
                            message: lang[store.state.lang].nameSameMessage
                        }];
                    }
                } else {
                    delete formData.parent;
                }
                submitting.value = true;

                formData.profile.contact.forEach(function (c) {
                    delete c.icon;
                    delete c.name;
                });
                delete formData.passwordCheck;
                if (!formData.profile.showName) {
                    formData.profile.showName = formData.profile.firstName + " " + formData.profile.lastName;
                }

                formData.userType = formData.userType.value;
                formData.school = formData.school.code;
                const toSend = new FormData();
                toSend.append("userData", JSON.stringify(formData));
                toSend.append("file", fileList.value[0].file);

                axios.post(Env.freePath + "addUser", toSend).then(function (res) {
                    if (res.data.error) {
                        Notify({
                            type: "danger",
                            message: lang[store.state.lang].failReg + res.data.error
                        });
                        submitting.value = false;
                        return;
                    }
                    Notify({
                        type: "success",
                        message: lang[store.state.lang].successReg
                    });
                    router.push("/login");
                }).catch(function (e) {
                    Notify({
                        type: "danger",
                        message: lang[store.state.lang].failServer + e
                    });
                    submitting.value = false;
                });
            }).catch(function (e) {
                Toast(e[0].message);
            });
        };

        const updatePassword = async function () {
            const error = passValidator(formData.system.password);
            if (error) {
                Toast(error);
                return;
            }
            if (formData.system.password !== formData.passwordCheck) {
                Toast(lang[store.state.lang].passSameMessage);
                return;
            }
            editPassword.value = false;
            const errorUpdate = await UpdateProfile({
                updatePass: formData.system.password
            });
            if (errorUpdate) {
                Toast.fail(errorUpdate);
            } else {
                Toast.success(lang[store.state.lang].successPass);
                Logout(store);
                router.push("/login");
            }
        };

        const updateParent = async function () {
            if (!formData.parent.firstName || !formData.parent.lastName) {
                Toast(lang[store.state.lang].parentNameMessage);
                return;
            }
            const error = callValidator(formData.parent.call);
            if (error) {
                Toast(error);
                return;
            }
            editParent.value = false;
            const errorUpdate = await UpdateProfile({
                updateParent: formData.parent
            });
            if (errorUpdate) {
                Toast.fail(errorUpdate);
            } else {
                Toast.success(lang[store.state.lang].successParent);
                Logout(store);
                router.push("/login");
            }
        };

        let iconChanged = false;
        const deleteIcon = function () {
            if (!editProfile.value) {
                return false;
            }
            iconChanged = true;
            console.log(iconChanged);
            return true;
        };
        const updateProfile = async function () {
            editProfile.value = false;
            Toast.loading({
                message: lang[store.state.lang].waitMessage,
                forbidClick: true,
                duration: 0
            });

            try {
                if (iconChanged) {
                    const toSend = new FormData();
                    toSend.append("file", fileList.value[0].file);

                    const res = await axios.post(Env.apiPath + "uploadUserIcon", toSend);
                    if (res.data.error) {
                        Toast.fail(res.data.error);
                        return;
                    } else {
                        formData.profile.icon = res.data.fileName;
                    }
                }
            } catch (e) {
                Toast.fail(e.message);
                return;
            }

            const errorUpdate = await UpdateProfile({
                updateProfile: formData.profile,
                updateTags: formData.meta.tags.personal
            });
            if (errorUpdate) {
                Toast.fail(errorUpdate);
            } else {
                Toast.success(lang[store.state.lang].successPersonal);
                Logout(store);
                router.push("/login");
            }
        };

        return {
            lang,
            userLang: computed(() => store.state.lang),
            Env,
            myTheme,
            formData,
            form,
            submitting,
            callValidator,
            passCheckValidator,
            passValidator,
            emailValidator,
            textFormatter,
            isParent,
            pickParent,
            userTypes,
            showUserType,
            pickUserType,
            userGenders,
            showDOB,
            pickDOB,
            minDate: new Date(1930, 0, 1),
            maxDate: new Date(new Date().getFullYear(), 11, 31),
            today: new Date(),
            changeIcon,
            loadIcon,
            iconOversize,
            checkIcon,
            fileList,
            schoolNames,
            schools,
            showSchools,
            pickSchool,
            contacts,
            showContacts,
            pickContact,
            removeContact,
            tagIn,
            addTag,
            removeTag,
            doSignup,
            editMode,
            editPassword,
            updatePassword,
            editParent,
            updateParent,
            editProfile,
            updateProfile,
            deleteIcon,
            infoTitle
        };
    }
}
</script>
