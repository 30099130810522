import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (modeData) {
    try {
        const toSend = new FormData();
        if (modeData.updatePass) {
            toSend.append("mode", "updatePass");
            toSend.append("updatePass", modeData.updatePass);
        }
        if (modeData.updateParent) {
            toSend.append("mode", "updateParent");
            toSend.append("updateParent", JSON.stringify(modeData.updateParent));
        }
        if (modeData.updateProfile) {
            toSend.append("mode", "updateProfile");
            toSend.append("updateProfile", JSON.stringify(modeData.updateProfile));
            toSend.append("updateTags", JSON.stringify(modeData.updateTags));
        }

        const res = await axios.post(Env.apiPath + "updateProfile", toSend);
        return res.data.error;
    } catch (err) {
        return err;
    }
}