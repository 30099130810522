export default {
    en: {
        confirm: "Confirm",
        cancel: "Cancel",
        title: "Create Account",
        titleEdit: "Profile",
        placeholderEnter: "Please enter",
        placeholderPick: "Please pick",
        accountTitle: "Account Information",
        infoTitle: "Personal Information",
        studentInfoTitle: "Student Personal Information",
        schoolLabel: "School",
        schoolMessage: "Please pick your school",
        userTypeLabel: "User Type",
        userTypeMessage: "Please pick your user type",
        student: "Student",
        parent: "Parent",
        isStudent: "Are you student?",
        parentTitle: "Parent Information (Parent must fill in self information if the student is under 18)",
        parentTip: "Please fill in your own (Parent) information first, then fill in your child's (Student) information",
        parentFirst: "Parent Firstname",
        parentFirstMessage: "Please enter parent's firstname",
        parentLast: "Parent Lastname",
        parentLastMessage: "Please enter parent's lastname",
        parentNumber: "Contact Number",
        parentNumberMessage: "Please provide parent's contact number",
        parentNameMessage: "请填家长姓名",
        emailLabel: "Email",
        emailMessage: "Please enter your email address",
        emailFormatMessage: "There is something wrong with your email address呦",
        passLabel: "Password",
        passConfirm: "Confirm Password",
        passConfirmMessage: "Please re-enter your password",
        passFormatMessage: "Please do not use space in password, begin your password with letters",
        passSameMessage: "You entered different passwords",
        firstName: "Firstname",
        firstNameMessage: "Please enter the registrant firstname",
        lastName: "Lastname",
        lastNameMessage: "Please enter the registrant lastname",
        showName: "Nickname",
        gender: "Gender",
        genderMessage: "Please pick a gender",
        dob: "Date of Birth",
        dobMessage: "Please pick a date",
        icon: "Profile Icon",
        iconMessage: "Please upload a photo",
        buttonRandom: "Random",
        bio: "Personal Bio",
        bioPlaceholder: "Please provide a short bio",
        personalTag: "Personal Tags",
        personalTagPlaceHolder: "Can input multiple tags",
        contactMethods: "Other Contacts",
        buttonAdd: "Add",
        buttonCancel: "Cancel",
        buttonEditPass: "Modify Password",
        buttonFinishPass: "Submit Password",
        dateTitle: "Year Month & Date",
        buttonEditParent: "Modify Parent Information",
        buttonFinishParent: "Submit Parent Information",
        buttonEditPersonal: "Modify Personal Information",
        buttonFinishPersonal: "Submit Personal Information",
        buttonSubmit: "Submit",
        parentAgeTip: "If the student is under 18, then must fill out parent information",
        fileMessage: "File size cannot exceed 1.5 MB",
        imageMessage: "Please only upload IMAGE file",
        nameSameMessage: "The student cannot have the same name as the parent",
        successReg: "Successfully created account, you can sign in right now",
        successPass: "Password sucessfully changed, please sign in again",
        successParent: "Successfully updated parent information, please sign in again",
        successPersonal: "Successfully updated personal information, please sign in again",
        failReg: "Sorry, cannot create account: ",
        failServer: "Sorry, something is wrong with the server: ",
        waitMessage: "Sending data, please wait..."
    },
    zh: {
        confirm: "确认",
        cancel: "取消",
        title: "用户注册",
        titleEdit: "我的信息",
        placeholderEnter: "请输入",
        placeholderPick: "请选择",
        accountTitle: "账户信息",
        infoTitle: "个人信息",
        studentInfoTitle: "学生信息",
        schoolLabel: "您的学校",
        schoolMessage: "请选择您的学校",
        userTypeLabel: "用户类型",
        userTypeMessage: "请选择一个用户类型",
        student: "学生",
        parent: "家长",
        isStudent: "是否本人",
        parentTitle: "家长信息 (18岁以下学生家长必填)",
        parentTip: "请先填写您自己（家长）的信息，然后再填写您的孩子（学生）的信息",
        parentFirst: "家长名",
        parentFirstMessage: "请填家长名",
        parentLast: "家长姓",
        parentLastMessage: "请填家长姓",
        parentNumber: "联系电话",
        parentNumberMessage: "请提供家长的联系电话",
        parentNameMessage: "请填家长姓名",
        emailLabel: "电子邮箱",
        emailMessage: "请填写电子邮箱",
        emailFormatMessage: "您输入的电子邮箱地址有错误呦",
        passLabel: "密码",
        passConfirm: "确认密码",
        passConfirmMessage: "请重复填写账户密码",
        passFormatMessage: "您输入的密码好像不太合适呦，请不要使用空格，并且字母在前",
        passSameMessage: "您输入的密码不一致",
        firstName: "名",
        firstNameMessage: "请填本人名",
        lastName: "姓",
        lastNameMessage: "请填本人姓",
        showName: "昵称",
        gender: "性别",
        genderMessage: "请选择性别",
        dob: "出生日期",
        dobMessage: "请选择出生日期",
        icon: "个人头像",
        iconMessage: "请选择上传一张头像",
        buttonRandom: "随机",
        bio: "个人简介",
        bioPlaceholder: "请输入简短的个人介绍",
        personalTag: "个人标签",
        personalTagPlaceHolder: "可输入多个",
        contactMethods: "其他联系方式",
        buttonAdd: "添加",
        buttonCancel: "取消",
        buttonEditPass: "修改密码",
        buttonFinishPass: "提交密码修改",
        dateTitle: "选择年月日",
        buttonEditParent: "修改家长信息",
        buttonFinishParent: "提交家长信息",
        buttonEditPersonal: "修改个人信息",
        buttonFinishPersonal: "提交个人信息",
        buttonSubmit: "提交",
        parentAgeTip: "学生未满18岁，请必填家长信息",
        fileMessage: "文件大小不能超过 1.5 MB",
        imageMessage: "请上传图片文件",
        nameSameMessage: "学生和家长的姓名不能一模一样",
        successReg: "注册成功，您已可以登录",
        successPass: "密码修改成功，请重新登录",
        successParent: "家长信息修改成功，请重新登录",
        successPersonal: "个人信息修改成功，请重新登录",
        failReg: "抱歉，注册失败：",
        failServer: "抱歉，服务器有错误：",
        waitMessage: "正在提交数据，请耐心等待..."
    }
}