export default {
    m: {
        zh: "男",
        en: "Male"
    },
    f: {
        zh: "女",
        en: "Female"
    },
    n: {
        zh: "不透露",
        en: "Netural"
    }
}